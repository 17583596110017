<template>
  <div class="container is-max-desktop">
    <div class="columns is-mobile page_forbidden">
      <div class="column">
        <img src="../../assets/images/rafiki.png" alt="" />
      </div>
      <div class="column">
        <div class="text_content">
          <h1 class="header_text">404 Page Not Found</h1>
          <p class="text_body">
            Oopps the page you are trying to access is no longer available.
            Please returns to the dashboard.
          </p>
        </div>
        <button @click="goToDAshboard()" class="btn_forbidden">
          Back to Dashboard
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goToDAshboard() {
      this.$router.push('/')
    },
  },
}
</script>

<style></style>
